import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import PubSub from "pubsub-js";
import SlickSlider from "react-slick";
// import SlideImage from "./SlideImage";
import Img from "gatsby-image";
import { WrapperContext } from "../Layout";
import SliderPagination from "./SliderPagination";

const SliderImage = ({ input }) => {
  const { isMobile } = useContext(WrapperContext);
  const [current, setCurrent] = useState(1);
  const [length, setLength] = useState(input.length || 0);
  // const [isMobile, setIsMobile] = useState(undefined);

  const sliderRef = useRef();
  const sliderID = useMemo(() => {
    return Math.round(Math.random() * 999);
  }, [input]);
  const TOPIC = `SLIDER_INDEX.${sliderID}`;
  console.log(TOPIC);
  useEffect(() => {
    // if (window.innerWidth < 768 && "ontouchstart" in window) setIsMobile(true);

    document.addEventListener("keydown", _onKey);
  }, []);

  // useEffect(() => {
  //   PubSub.publish("SLIDER_INDEX", {
  //     current: current,
  //     length: length,
  //     sliderID: sliderID,
  //   });
  // }, [current]);

  const _onKey = (e) => {
    // console.log(e.keyCode)
    switch (e.keyCode) {
      case 37:
        sliderRef.current.slickPrev();
        break;
      case 39:
        sliderRef.current.slickNext();
        break;
    }
  };
  // const _onEdge = () => {};
  // const _sliderBeforeChange = (oldIndex, newIndex) => {};
  const _sliderAfterChange = (index) => {
    // setCurrent(index + 1);
    PubSub.publish(TOPIC, {
      current: index + 1,
      length: length,
      sliderID: sliderID,
    });
    // onChange(index + 1);
  };

  const settings = {
    fade: !isMobile,
    dots: false,
    speed: isMobile ? 400 : 10,
    swipe: isMobile,
    adaptiveHeight: true,
    // initialSlide: startAt,
    // lazyLoad: true,
    // beforeChange: _sliderBeforeChange,
    afterChange: _sliderAfterChange,
    // onEdge: _onEdge,
  };

  return (
    <div className={"slider"}>
      <SlickSlider ref={sliderRef} {...settings}>
        {input.map((el, i) => (
          <div className="slide" key={i}>
            <figure className="">
              <Img fluid={el.image.fluid} alt={el.image.alt} />
            </figure>
          </div>
        ))}
      </SlickSlider>
      {/* <div className="slider-pagination pt-s fSeg">{`${current}/${length}`}</div> */}
      <div className="pt-s">
        <SliderPagination total={length} TOPIC={TOPIC} />
      </div>
    </div>
  );
};

export default SliderImage;
