import * as React from "react";
import { withUnpublishedPreview } from "gatsby-source-prismic";

import templatePage from "../templates/template-page";
import templateSingleProjet from "../templates/template-single-projet";

// import { Layout } from "../components/Layout";

const NotFoundPage = () => (
  <div className="page-404">
    <div className="inner ">
      <h1>Page Not Found</h1>
      <p>The page requested couldn't be found</p>
      <a href="/">Back home</a>
    </div>
  </div>
);

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    page: templatePage,
    projet: templateSingleProjet,
  },
});

// import React from "react"

// const Page404 = () => {
//   return (
//     <div className="page-404">
//       <div className="inner ">
//         <h1>Page Not Found</h1>
//         <p>The page requested couldn't be found</p>
//         <a href="/">Back home</a>
//       </div>
//     </div>
//   )
// }

// export default Page404
