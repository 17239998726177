import React from "react";
import Img from "gatsby-image";
import { RichText } from "prismic-reactjs";
import SliderImage from "./SliderImage";
import { htmlSerializer } from "../../core/utils";

const TexteImages = ({ input }) => {
  const { chapo, texte } = input.primary;
  const { items } = input;
  // const images = items.map((el) => el.image);
  // console.log(items);
  return (
    <div className="texte-images mb3- mb-l fL">
      <div className="row">
        <div className="col-md-6 col-xs-12">
          <div className="pssty">
            <div className="row no-gutter">
              <div className="col-md-4 col-xs-12"></div>
              <div className="col-md-8 col-xs-12">
                <div className="chapo-  xab- mb-m">
                  {RichText.render(chapo.raw)}
                </div>
                <div className="texte">
                  <RichText
                    render={texte.raw}
                    htmlSerializer={htmlSerializer}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xs-12">
          <div className="pssty">
            {/* <pre>{JSON.stringify(items)}</pre> */}
            {items.length > 1 && <SliderImage input={items} />}
            {items.length === 1 && items[0].image.fluid && (
              <Img {...items[0].image} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TexteImages;
