import React, { useEffect, useState } from "react";
import BgImg from "gatsby-background-image";
import Div100vh from "react-div-100vh";

const ImageRandom = ({ size, input }) => {
  return (
    <div className="image-random">
      {size === "cover" ? (
        <Div100vh>
          <figure className="h100">
            <BgImg
              fluid={input[0].image.fluid}
              alt={input[0].image.alt}
              className="h100"
              style={{
                backgroundSize: size,
              }}
            />
          </figure>
        </Div100vh>
      ) : (
        <figure className="h100">
          <BgImg
            fluid={input[0].image.fluid}
            alt={input[0].image.alt}
            className="h100"
            style={{
              backgroundSize: size,
            }}
          />
        </figure>
      )}
    </div>
  );
};

export default ImageRandom;
