import React, { useEffect, useState } from "react";
import PubSub from "pubsub-js";

const SliderLegende = ({ input }) => {
  // console.log(input);
  const [legende, setLegende] = useState("");

  useEffect(() => {
    const token = PubSub.subscribe("SLIDER_INDEX", (e, d) => {
      // console.log(d);
      if (d.current && input[d.current - 1]) {
        // console.log(input[d.current - 1]);
        setLegende(input[d.current - 1].image.alt);
      }
      // setCurrent(d.current);
    });

    return () => PubSub.unsubscribe(token);
  }, []);

  return <div className="slider-legende psa- left- z250">{legende}</div>;
};

export default SliderLegende;
