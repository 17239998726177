import React, { useEffect, useMemo, useState } from "react";
import Img from "gatsby-image";
import Slider from "./Slider";
import SliderLegende from "./SliderLegende";
import { shuffle } from "../../core/utils";

const ImagesGridToSlider = ({ input }) => {
  const [isSlider, setIsSlider] = useState();
  const [slideIndex, setSlideIndex] = useState(0);
  const [suffledImages, setShuffledImagez] = useState([]);

  useEffect(() => {
    const shuffled = shuffle(input);
    setShuffledImagez(shuffled);
  }, []);

  const triggerSliderAt = (index) => {
    // console.log(index);
    setSlideIndex(index);
    setIsSlider(true);
  };

  useEffect(() => {
    document.documentElement.classList.toggle("no-scroll", isSlider);
    // if (isSlider) {
    //   document.documentElement.classList.add("no-scroll");
    // } else {
    //   document.documentElement.classList.remove("no-scroll");
    // }
  }, [isSlider]);
  console.log(suffledImages);
  // const imagesSuffled = useMemo(() => {
  //   return shuffle(input);
  // }, []);

  return (
    <div className="images-grid">
      <div className="row">
        {suffledImages.map((el, i) => (
          <div
            className="card col-md-3 col-xs-12 curp mb-m"
            key={i}
            onClick={() => triggerSliderAt(i)}
          >
            <figure>
              <div className="outter a-r-a4">
                <div className="inner x xdc xjc xje">
                  <Img {...el.image} />
                </div>
              </div>
              <figcaption className="card-body fSeg ttu">
                {el.image.alt}
              </figcaption>
            </figure>
          </div>
        ))}
      </div>

      {isSlider && (
        <div className="modal fSeg">
          <Slider
            size="contain"
            input={input}
            autoplay={false}
            startAt={slideIndex}
          />
          <div className="footer x  xjb psa b0 w100 ttu">
            <div className="pr-m">
              <SliderLegende input={input} />
            </div>
            <div
              className="right  curp z10000"
              onClick={() => setIsSlider(false)}
            >
              <div className=" btn-toggle-wrapper">
                <div className="btn-toggle- rotate-45">Fermer</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImagesGridToSlider;
